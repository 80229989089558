<template>
  <div>
    <!-- 判断是否在空白页打开 -->
    <template v-if="!isOneself">
      <div class="app-wrapper">
        <!-- 系统标题 -->
        <div class="system-title">
          <div class="image">
            <img src="../assets/logo.png" alt="" srcset=""/>
          </div>
        </div>
        <!-- 左侧菜单 -->
        <div class="sidebar-container">
          <ElMenu/>
        </div>
        <!-- 右侧展示内容 -->
        <div class="main-container">
          <HeaderNav class="header-main"/>
          <AppContent class="app-main"/>
        </div>
      </div>
    </template>
    <!-- 如果在空白页打开则不显示框架 -->
    <template v-else>
      <AppContent/>
    </template>
  </div>
</template>

<script>
import ElMenu from './components/ElMenu/index.vue'
import HeaderNav from './components/HeaderNav.vue'
import AppContent from './components/AppContent.vue'

export default {
  data() {
    return {
      // 默认页面在框架内显示
      isOneself: false
    }
  },
  components: {
    ElMenu,
    HeaderNav,
    AppContent,
  },
  mounted() {
    // 初始化加载一次判断是否在空白页打开
    this.isOneself = this.$route.meta.oneself
  },
  methods: {
  },
  watch: {
    // 监听route变化
    $route: function (newVal) {
      // 判断页面是否在空白页打开
      this.isOneself = newVal.meta.oneself
    },
  },
}
</script>

<style lang="less" scoped>
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  // 系统标题
  .system-title {
    display: flex;
    position: fixed;
    justify-content: space-around;
    align-items: center;
    color: white;
    width: 170px;
    height: 60px;
    top: 0;
    left: 0;
    padding: 0 10px;
    box-sizing: border-box;

    .image {
      height: 50px;
      border-radius: 20px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  // 左侧菜单
  .sidebar-container {
    -webkit-transition: width 0.28s;
    transition: width 0.28s;
    width: 180px !important;
    background-color: white;
    height: 100%;
    position: fixed;
    font-size: 0;
    top: 60px;
    bottom: 0;
    left: 0;
    z-index: 1001;
    overflow: hidden;
    -webkit-box-shadow: 1px 0 3px rgba(113, 132, 150, 0.35);
    box-shadow: 1px 0 3px rgba(35, 114, 190, 0.35);
    // border-right: 1px solid rgba(0, 0, 0, 0.2);
    & > div {
      width: 201px !important;
    }
  }

  // 右侧主题内容
  .main-container {
    -webkit-transition: margin-left 0.28s;
    transition: margin-left 0.28s;
    position: fixed;
    width: calc(100vw - 175px);
    top: 60px;
    right: 0;
    bottom: 0;
    left: 175px;
    // 头部导航
    .header-main {
      position: fixed;
      height: 60px;
      width: 100%;
      left: 150px;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      padding-left: 10px;
      box-sizing: border-box;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    // 内容展示区
    .app-main {
      position: relative;
      height: 95vh;
      width: 100%;
      overflow: auto;
      background-color: #f0f0f0;
    }
  }
}
</style>
