import Vue from 'vue'
import VueRouter from 'vue-router'
import Layouts from "../layouts";
import Cookies from "js-cookie";

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        redirect: "license",
        component: Layouts,
        children: [
            {
                path: "/login",
                name: "Login",
                meta: {title: "登录", oneself: true, hide: true,},
                component: () => import("../views/Login.vue"),
            },
            {
                path: "/license",
                meta: {title: "授权管理", icon: "el-icon-s-check"},
                component: () => import("../views/License.vue"),
            },
            {
                path: "/account",
                meta: {title: "帐号管理", icon: "el-icon-s-custom"},
                component: () => import("../views/Account.vue"),
            }
        ],
    },
];

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法，取消路由重复的报错
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};


const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});

// 前置路由拦截器
router.beforeEach((to, from, next) => {
    // 设置当前页签名称
    document.title = to.meta.title;
    // 没有登录并且要去的页面不是登录页面，在强制跳转到登录
    if (to.path === "/login") {
        Cookies.remove('userInfo')
        next()
    } else if (!Cookies.get('userInfo')) {
        next('/login')
    } else {
        next()
    }
});

export default router
